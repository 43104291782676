<div class="mat-elevation-z8">
  <button class="svagriha" (click)="move()">Svagriha Projects</button>
  <table mat-table [dataSource]="dataSource">
    <!-- Project Details Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef>Project Details</th>
      <td mat-cell *matCellDef="let element">
        <div>
          {{ element.name }}
        </div>
        <!-- <div>{{element?.project?.org}}</div>
        <div>Version: <strong>{{element?.project?.version}}</strong></div> -->
      </td>
    </ng-container>

    <!-- Client Deatils Column -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Client Details</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.client.name }}</div>
        <div>{{ element.client.state }}</div>
      </td>
    </ng-container>

    <!-- Registered Date Column -->
    <ng-container matColumnDef="regDate">
      <th mat-header-cell *matHeaderCellDef>Registered Date</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.created_at }}</div>
      </td>
    </ng-container>

    <!-- Proj Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <div>Project : {{ element.status }}</div>
        <div *ngIf="element.payment_status?.status; else noRecord">
          Payment: {{ element.payment_status.status }}
        </div>
        <div *ngIf="element.workshop_status?.status; else noRecord">
          Workshop: {{ element.workshop_status.status }}
        </div>
        <div *ngIf="element.site_visit_status?.status; else noRecord">
          Site Visit: {{ element.site_visit_status.status }}
        </div>
      </td>
    </ng-container>

    <!-- Proj Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ element.type }}</div>
      </td>
    </ng-container>

    <!-- Proj Coordinator Column -->
    <ng-container matColumnDef="coordinator">
      <th mat-header-cell *matHeaderCellDef>Coordinator</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.coordinator?.name; else noRecord">
          {{ element.coordinator.name }}
        </div>
      </td>
    </ng-container>

    <!-- Proj Coordinator Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button (click)="rowData(element)">view</button>
      </td>
    </ng-container>
    <ng-template #noRecord>
      <p>NA</p>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Projects" [length]="totalItems" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="onPageChange($event)">
  </mat-paginator>
</div>