// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import config from '../assets/config/auth_config.json';







const { domain, clientId, authorizationParams: { audience }, apiUri, errorPath, adminRedirectUrl, clientRedirectUrl, coordinatorRedirectUrl,evaluatorRedirectUrl,superAdminRedirectUrl, redirectUri,svagriha_url,griha_url ,apiDomain,appName,verion} = config as {
  adminRedirectUrl: string
  clientRedirectUrl: string
  coordinatorRedirectUrl: string
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;

  },
  redirectUri: string,
  svagriha_url:string,
  griha_url:string,
  apiUri: string;
  errorPath: string;
  apiDomain:string;
  appName:string;
  verion:string;
  evaluatorRedirectUrl:string;
  superAdminRedirectUrl:string;
};

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'https://dev-p2arb775bxkkgozg.us.auth0.com/api/v2/' ? { audience } : null),
      redirect_uri: griha_url+'/'+redirectUri,
      // redirect_uri:window.location.origin
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${griha_url}/${apiUri}/*`],
  },
  redirect: {
    adminRedirectUrl:svagriha_url+'/'+adminRedirectUrl,
    clientRedirectUrl:svagriha_url+'/'+clientRedirectUrl,
    coordinatorRedirectUrl:svagriha_url+'/'+coordinatorRedirectUrl,
    evaluatorRedirectUrl:svagriha_url+'/'+evaluatorRedirectUrl,
    superAdminRedirectUrl:svagriha_url+'/'+superAdminRedirectUrl
  },
  apiDomain:apiDomain,
  appName:appName,
  verion:verion,
  // logoutUrl:griha_url+'/'+redirectUri
  logoutUrl:griha_url
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
