<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <img class="image" src="/assets/Untitled.jpg" alt="image" />
  <button
    id="qsLoginBtn"
    class="btn btn-primary btn-margin button"
    (click)="loginWithRedirect()"
  >
    Log in
  </button>
</ng-template>
